import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/fuelling/Development/sappenin/ilpv4-dev-website/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "clara-connector-testilpv4-devclara"
    }}>{`Clara Connector (`}<inlineCode parentName="h1">{`test.ilpv4-dev.clara`}</inlineCode>{`)`}</h1>
    <p>{`Clara is a `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/44deb112e51db27e89bf1b02d115c272dda2cddb/0034-connector-requirements/0034-connector-requirements.md#link-relations"
      }}>{`Tier1`}</a>{`
Connector implemented in Rust via the `}<a parentName="p" {...{
        "href": "https://github.com/emschwartz/interledger-rs"
      }}>{`interledger-rs`}</a>{` project.`}</p>
    <h2 {...{
      "id": "testnet-configuration"
    }}>{`Testnet Configuration`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ILP Address`}</strong>{`: `}<inlineCode parentName="li">{`test.ilpv4-dev.clara`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Http Url`}</strong>{`: `}<inlineCode parentName="li">{`https://clara-dot-ilpv4-dev.appspot.com`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "claras-peers"
    }}>{`Clara's Peers`}</h2>
    <h3 {...{
      "id": "marty"
    }}>{`Marty`}</h3>
    <p>{`Clara peers with `}<a parentName="p" {...{
        "href": "marty-connector"
      }}>{`Marty`}</a>{` using `}<a parentName="p" {...{
        "href": "https://github.com/interledger/rfcs/blob/master/0035-ilp-over-http/0035-ilp-over-http.md"
      }}>{`Ilp-over-Http`}</a>{`.`}</p>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="470" height="79">
      <path d="M 2.878906 13.319336 L 2.878906 6.412109 L 6.729980 6.412109 L 6.729980 7.335938 L 3.738281 7.335938 L 3.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 26.319336 L 2.878906 12.300781 L 3.738281 12.300781 L 3.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 39.319336 L 2.878906 25.300781 L 3.738281 25.300781 L 3.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 52.319336 L 2.878906 38.300781 L 3.738281 38.300781 L 3.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 65.319336 L 2.878906 51.300781 L 3.738281 51.300781 L 3.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 2.878906 72.335938 L 2.878906 64.300781 L 3.738281 64.300781 L 3.738281 71.412109 L 6.729980 71.412109 L 6.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 6.892578 72.335938 L 6.892578 71.412109 L 13.729980 71.412109 L 13.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 13.892578 72.335938 L 13.892578 71.412109 L 20.729980 71.412109 L 20.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 20.892578 72.335938 L 20.892578 71.412109 L 27.729980 71.412109 L 27.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.892578 72.335938 L 27.892578 71.412109 L 34.729980 71.412109 L 34.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.892578 72.335938 L 34.892578 71.412109 L 41.729980 71.412109 L 41.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 41.892578 72.335938 L 41.892578 71.412109 L 48.729980 71.412109 L 48.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 48.892578 72.335938 L 48.892578 71.412109 L 55.729980 71.412109 L 55.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 55.892578 72.335938 L 55.892578 71.412109 L 62.729980 71.412109 L 62.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 62.892578 72.335938 L 62.892578 71.412109 L 69.729980 71.412109 L 69.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 69.892578 72.335938 L 69.892578 71.412109 L 76.729980 71.412109 L 76.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 76.892578 72.335938 L 76.892578 71.412109 L 83.729980 71.412109 L 83.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 83.892578 72.335938 L 83.892578 71.412109 L 90.729980 71.412109 L 90.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 90.892578 72.335938 L 90.892578 71.412109 L 97.729980 71.412109 L 97.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 97.892578 72.335938 L 97.892578 71.412109 L 104.729980 71.412109 L 104.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.892578 72.335938 L 104.892578 71.412109 L 111.729980 71.412109 L 111.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.892578 72.335938 L 111.892578 71.412109 L 118.729980 71.412109 L 118.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 118.892578 72.335938 L 118.892578 71.412109 L 125.729980 71.412109 L 125.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.892578 72.335938 L 125.892578 71.412109 L 132.729980 71.412109 L 132.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 132.892578 72.335938 L 132.892578 71.412109 L 139.729980 71.412109 L 139.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 139.892578 72.335938 L 139.892578 71.412109 L 146.729980 71.412109 L 146.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.892578 72.335938 L 146.892578 71.412109 L 153.729980 71.412109 L 153.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 153.892578 72.335938 L 153.892578 71.412109 L 160.729980 71.412109 L 160.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 160.892578 72.335938 L 160.892578 71.412109 L 163.878906 71.412109 L 163.878906 64.300781 L 164.738281 64.300781 L 164.738281 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 303.878906 72.335938 L 303.878906 64.300781 L 304.738281 64.300781 L 304.738281 71.412109 L 307.729980 71.412109 L 307.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 307.892578 72.335938 L 307.892578 71.412109 L 314.729980 71.412109 L 314.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 314.892578 72.335938 L 314.892578 71.412109 L 321.729980 71.412109 L 321.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 321.892578 72.335938 L 321.892578 71.412109 L 328.729980 71.412109 L 328.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.892578 72.335938 L 328.892578 71.412109 L 335.729980 71.412109 L 335.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 335.892578 72.335938 L 335.892578 71.412109 L 342.729980 71.412109 L 342.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 342.892578 72.335938 L 342.892578 71.412109 L 349.729980 71.412109 L 349.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.892578 72.335938 L 349.892578 71.412109 L 356.729980 71.412109 L 356.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 356.892578 72.335938 L 356.892578 71.412109 L 363.729980 71.412109 L 363.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 363.892578 72.335938 L 363.892578 71.412109 L 370.729980 71.412109 L 370.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 370.892578 72.335938 L 370.892578 71.412109 L 377.729980 71.412109 L 377.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 377.892578 72.335938 L 377.892578 71.412109 L 384.729980 71.412109 L 384.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 384.892578 72.335938 L 384.892578 71.412109 L 391.729980 71.412109 L 391.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 391.892578 72.335938 L 391.892578 71.412109 L 398.729980 71.412109 L 398.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 398.892578 72.335938 L 398.892578 71.412109 L 405.729980 71.412109 L 405.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.892578 72.335938 L 405.892578 71.412109 L 412.729980 71.412109 L 412.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 412.892578 72.335938 L 412.892578 71.412109 L 419.729980 71.412109 L 419.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 419.892578 72.335938 L 419.892578 71.412109 L 426.729980 71.412109 L 426.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.892578 72.335938 L 426.892578 71.412109 L 433.729980 71.412109 L 433.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 433.892578 72.335938 L 433.892578 71.412109 L 440.729980 71.412109 L 440.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.892578 72.335938 L 440.892578 71.412109 L 447.729980 71.412109 L 447.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 447.892578 72.335938 L 447.892578 71.412109 L 454.729980 71.412109 L 454.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 454.892578 72.335938 L 454.892578 71.412109 L 461.729980 71.412109 L 461.729980 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 461.892578 72.335938 L 461.892578 71.412109 L 464.878906 71.412109 L 464.878906 64.300781 L 465.738281 64.300781 L 465.738281 72.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 17.297852 28.276367 L 17.297852 29.984375 L 19.542969 29.984375 L 19.542969 30.752441 L 17.297852 30.752441 L 17.297852 34.018066 Q 17.297852 34.684082 17.550293 34.947266 Q 17.802734 35.210449 18.431152 35.210449 L 19.542969 35.210449 L 19.542969 36.000000 L 18.334473 36.000000 Q 17.222656 36.000000 16.766113 35.554199 Q 16.309570 35.108398 16.309570 34.018066 L 16.309570 30.752441 L 14.703613 30.752441 L 14.703613 29.984375 L 16.309570 29.984375 L 16.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 67.592285 40.116211 Q 66.877930 41.340820 66.526123 42.557373 Q 66.174316 43.773926 66.174316 45.009277 Q 66.174316 46.239258 66.526123 47.458496 Q 66.877930 48.677734 67.592285 49.913086 L 66.732910 49.913086 Q 65.921875 48.634766 65.524414 47.423584 Q 65.126953 46.212402 65.126953 45.009277 Q 65.126953 43.811523 65.524414 42.597656 Q 65.921875 41.383789 66.732910 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 163.878906 65.319336 L 163.878906 51.300781 L 164.738281 51.300781 L 164.738281 58.412109 L 167.729980 58.412109 L 167.729980 59.335938 L 164.738281 59.335938 L 164.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 167.892578 59.335938 L 167.892578 58.412109 L 174.729980 58.412109 L 174.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 174.892578 59.335938 L 174.892578 58.412109 L 181.729980 58.412109 L 181.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 181.892578 59.335938 L 181.892578 58.412109 L 188.729980 58.412109 L 188.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 188.892578 59.335938 L 188.892578 58.412109 L 195.729980 58.412109 L 195.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 195.892578 59.335938 L 195.892578 58.412109 L 202.729980 58.412109 L 202.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 202.892578 59.335938 L 202.892578 58.412109 L 209.729980 58.412109 L 209.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 209.892578 59.335938 L 209.892578 58.412109 L 216.729980 58.412109 L 216.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 216.892578 59.335938 L 216.892578 58.412109 L 223.729980 58.412109 L 223.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 224.735840 53.980957 L 225.826172 53.980957 L 225.826172 57.053223 L 228.796387 57.053223 L 228.796387 53.980957 L 229.886719 53.980957 L 229.886719 62.000000 L 228.796387 62.000000 L 228.796387 57.966309 L 225.826172 57.966309 L 225.826172 62.000000 L 224.735840 62.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 234.297852 54.276367 L 234.297852 55.984375 L 236.542969 55.984375 L 236.542969 56.752441 L 234.297852 56.752441 L 234.297852 60.018066 Q 234.297852 60.684082 234.550293 60.947266 Q 234.802734 61.210449 235.431152 61.210449 L 236.542969 61.210449 L 236.542969 62.000000 L 235.334473 62.000000 Q 234.222656 62.000000 233.766113 61.554199 Q 233.309570 61.108398 233.309570 60.018066 L 233.309570 56.752441 L 231.703613 56.752441 L 231.703613 55.984375 L 233.309570 55.984375 L 233.309570 54.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 241.297852 54.276367 L 241.297852 55.984375 L 243.542969 55.984375 L 243.542969 56.752441 L 241.297852 56.752441 L 241.297852 60.018066 Q 241.297852 60.684082 241.550293 60.947266 Q 241.802734 61.210449 242.431152 61.210449 L 243.542969 61.210449 L 243.542969 62.000000 L 242.334473 62.000000 Q 241.222656 62.000000 240.766113 61.554199 Q 240.309570 61.108398 240.309570 60.018066 L 240.309570 56.752441 L 238.703613 56.752441 L 238.703613 55.984375 L 240.309570 55.984375 L 240.309570 54.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 247.014160 61.242676 L 247.014160 64.288086 L 246.020508 64.288086 L 246.020508 55.984375 L 247.014160 55.984375 L 247.014160 56.752441 Q 247.261230 56.306641 247.672119 56.072998 Q 248.083008 55.839355 248.620117 55.839355 Q 249.710449 55.839355 250.330811 56.682617 Q 250.951172 57.525879 250.951172 59.019043 Q 250.951172 60.485352 250.328125 61.320557 Q 249.705078 62.155762 248.620117 62.155762 Q 248.072266 62.155762 247.661377 61.922119 Q 247.250488 61.688477 247.014160 61.242676 Z M 249.914551 58.997559 Q 249.914551 57.848145 249.552002 57.262695 Q 249.189453 56.677246 248.475098 56.677246 Q 247.755371 56.677246 247.384766 57.265381 Q 247.014160 57.853516 247.014160 58.997559 Q 247.014160 60.136230 247.384766 60.727051 Q 247.755371 61.317871 248.475098 61.317871 Q 249.189453 61.317871 249.552002 60.732422 Q 249.914551 60.146973 249.914551 58.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 256.774902 53.980957 L 257.795410 53.980957 L 253.573730 63.020508 L 252.547852 63.020508 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 261.003418 61.086914 L 264.687988 61.086914 L 264.687988 62.000000 L 259.816406 62.000000 L 259.816406 61.086914 Q 260.820801 60.028809 261.572754 59.217773 Q 262.324707 58.406738 262.609375 58.073730 Q 263.146484 57.418457 263.334473 57.012939 Q 263.522461 56.607422 263.522461 56.183105 Q 263.522461 55.511719 263.127686 55.130371 Q 262.732910 54.749023 262.045410 54.749023 Q 261.556641 54.749023 261.019531 54.926270 Q 260.482422 55.103516 259.880859 55.463379 L 259.880859 54.367676 Q 260.434082 54.104492 260.968506 53.970215 Q 261.502930 53.835938 262.023926 53.835938 Q 263.200195 53.835938 263.917236 54.461670 Q 264.634277 55.087402 264.634277 56.102539 Q 264.634277 56.618164 264.395264 57.133789 Q 264.156250 57.649414 263.619141 58.272461 Q 263.318359 58.621582 262.746338 59.239258 Q 262.174316 59.856934 261.003418 61.086914 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 265.892578 59.335938 L 265.892578 58.412109 L 272.729980 58.412109 L 272.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 272.892578 59.335938 L 272.892578 58.412109 L 279.729980 58.412109 L 279.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 279.892578 59.335938 L 279.892578 58.412109 L 286.729980 58.412109 L 286.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 286.892578 59.335938 L 286.892578 58.412109 L 293.729980 58.412109 L 293.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 293.892578 59.335938 L 293.892578 58.412109 L 300.729980 58.412109 L 300.729980 59.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 301.644531 61.505859 L 306.365723 59.142578 L 301.644531 56.779297 Z M 301.032227 62.418945 L 301.032227 55.866211 L 307.590332 59.142578 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 464.878906 65.319336 L 464.878906 51.300781 L 465.738281 51.300781 L 465.738281 65.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 74.082031 45.213379 Q 74.500977 45.320801 74.796387 45.618896 Q 75.091797 45.916992 75.532227 46.808594 L 76.622559 49.000000 L 75.457031 49.000000 L 74.500977 46.975098 Q 74.087402 46.110352 73.757080 45.860596 Q 73.426758 45.610840 72.895020 45.610840 L 71.858398 45.610840 L 71.858398 49.000000 L 70.768066 49.000000 L 70.768066 40.980957 L 73.002441 40.980957 Q 74.323730 40.980957 75.027344 41.577148 Q 75.730957 42.173340 75.730957 43.301270 Q 75.730957 44.096191 75.298584 44.598389 Q 74.866211 45.100586 74.082031 45.213379 Z M 71.858398 41.872559 L 71.858398 44.719238 L 73.045410 44.719238 Q 73.824219 44.719238 74.205566 44.370117 Q 74.586914 44.020996 74.586914 43.301270 Q 74.586914 42.608398 74.181396 42.240479 Q 73.775879 41.872559 73.002441 41.872559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 78.047363 46.722656 L 78.047363 42.995117 L 79.035645 42.995117 L 79.035645 46.722656 Q 79.035645 47.533691 79.322998 47.915039 Q 79.610352 48.296387 80.211914 48.296387 Q 80.910156 48.296387 81.280762 47.804932 Q 81.651367 47.313477 81.651367 46.395020 L 81.651367 42.995117 L 82.645020 42.995117 L 82.645020 49.000000 L 81.651367 49.000000 L 81.651367 48.097656 Q 81.388184 48.618652 80.934326 48.887207 Q 80.480469 49.155762 79.873535 49.155762 Q 78.949707 49.155762 78.498535 48.551514 Q 78.047363 47.947266 78.047363 46.722656 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 89.226074 43.193848 L 89.226074 44.160645 Q 88.801758 43.913574 88.372070 43.790039 Q 87.942383 43.666504 87.496582 43.666504 Q 86.825195 43.666504 86.494873 43.884033 Q 86.164551 44.101562 86.164551 44.547363 Q 86.164551 44.950195 86.411621 45.148926 Q 86.658691 45.347656 87.641602 45.535645 L 88.039062 45.610840 Q 88.774902 45.750488 89.153564 46.169434 Q 89.532227 46.588379 89.532227 47.259766 Q 89.532227 48.151367 88.898438 48.653564 Q 88.264648 49.155762 87.136719 49.155762 Q 86.690918 49.155762 86.202148 49.061768 Q 85.713379 48.967773 85.144043 48.779785 L 85.144043 47.759277 Q 85.697266 48.043945 86.202148 48.186279 Q 86.707031 48.328613 87.158203 48.328613 Q 87.813477 48.328613 88.173340 48.062744 Q 88.533203 47.796875 88.533203 47.318848 Q 88.533203 46.631348 87.217285 46.368164 L 87.174316 46.357422 L 86.803711 46.282227 Q 85.949707 46.115723 85.557617 45.720947 Q 85.165527 45.326172 85.165527 44.644043 Q 85.165527 43.779297 85.750977 43.309326 Q 86.336426 42.839355 87.421387 42.839355 Q 87.904785 42.839355 88.350586 42.927979 Q 88.796387 43.016602 89.226074 43.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 94.297852 41.276367 L 94.297852 42.984375 L 96.542969 42.984375 L 96.542969 43.752441 L 94.297852 43.752441 L 94.297852 47.018066 Q 94.297852 47.684082 94.550293 47.947266 Q 94.802734 48.210449 95.431152 48.210449 L 96.542969 48.210449 L 96.542969 49.000000 L 95.334473 49.000000 Q 94.222656 49.000000 93.766113 48.554199 Q 93.309570 48.108398 93.309570 47.018066 L 93.309570 43.752441 L 91.703613 43.752441 L 91.703613 42.984375 L 93.309570 42.984375 L 93.309570 41.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 99.869141 40.116211 L 100.728516 40.116211 Q 101.539551 41.383789 101.937012 42.597656 Q 102.334473 43.811523 102.334473 45.009277 Q 102.334473 46.217773 101.937012 47.431641 Q 101.539551 48.645508 100.728516 49.913086 L 99.869141 49.913086 Q 100.583496 48.666992 100.935303 47.447754 Q 101.287109 46.228516 101.287109 45.009277 Q 101.287109 43.784668 100.935303 42.565430 Q 100.583496 41.346191 99.869141 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 163.878906 52.319336 L 163.878906 38.300781 L 164.738281 38.300781 L 164.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 303.878906 52.319336 L 303.878906 38.300781 L 304.738281 38.300781 L 304.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 368.592285 40.116211 Q 367.877930 41.340820 367.526123 42.557373 Q 367.174316 43.773926 367.174316 45.009277 Q 367.174316 46.239258 367.526123 47.458496 Q 367.877930 48.677734 368.592285 49.913086 L 367.732910 49.913086 Q 366.921875 48.634766 366.524414 47.423584 Q 366.126953 46.212402 366.126953 45.009277 Q 366.126953 43.811523 366.524414 42.597656 Q 366.921875 41.383789 367.732910 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 371.585449 48.672363 L 371.585449 47.404785 Q 372.074219 47.839844 372.595215 48.057373 Q 373.116211 48.274902 373.674805 48.274902 Q 374.442871 48.274902 374.746338 47.874756 Q 375.049805 47.474609 375.049805 46.384277 L 375.049805 41.894043 L 373.003418 41.894043 L 373.003418 40.980957 L 376.134766 40.980957 L 376.134766 46.384277 Q 376.134766 47.898926 375.568115 48.527344 Q 375.001465 49.155762 373.674805 49.155762 Q 373.159180 49.155762 372.648926 49.037598 Q 372.138672 48.919434 371.585449 48.672363 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 381.770508 45.976074 L 381.442871 45.976074 Q 380.578125 45.976074 380.140381 46.279541 Q 379.702637 46.583008 379.702637 47.184570 Q 379.702637 47.727051 380.030273 48.027832 Q 380.357910 48.328613 380.937988 48.328613 Q 381.754395 48.328613 382.221680 47.761963 Q 382.688965 47.195312 382.694336 46.196289 L 382.694336 45.976074 Z M 383.687988 45.567871 L 383.687988 49.000000 L 382.694336 49.000000 L 382.694336 48.108398 Q 382.377441 48.645508 381.896729 48.900635 Q 381.416016 49.155762 380.728516 49.155762 Q 379.810059 49.155762 379.262207 48.637451 Q 378.714355 48.119141 378.714355 47.249023 Q 378.714355 46.244629 379.388428 45.723633 Q 380.062500 45.202637 381.367676 45.202637 L 382.694336 45.202637 L 382.694336 45.046875 Q 382.688965 44.327148 382.329102 44.002197 Q 381.969238 43.677246 381.179688 43.677246 Q 380.674805 43.677246 380.159180 43.822266 Q 379.643555 43.967285 379.154785 44.246582 L 379.154785 43.258301 Q 379.702637 43.048828 380.204834 42.944092 Q 380.707031 42.839355 381.179688 42.839355 Q 381.926270 42.839355 382.455322 43.059570 Q 382.984375 43.279785 383.312012 43.720215 Q 383.516113 43.988770 383.602051 44.383545 Q 383.687988 44.778320 383.687988 45.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 385.537109 42.984375 L 386.562988 42.984375 L 388.308594 48.033203 L 390.059570 42.984375 L 391.085449 42.984375 L 388.947754 49.000000 L 387.674805 49.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 395.770508 45.976074 L 395.442871 45.976074 Q 394.578125 45.976074 394.140381 46.279541 Q 393.702637 46.583008 393.702637 47.184570 Q 393.702637 47.727051 394.030273 48.027832 Q 394.357910 48.328613 394.937988 48.328613 Q 395.754395 48.328613 396.221680 47.761963 Q 396.688965 47.195312 396.694336 46.196289 L 396.694336 45.976074 Z M 397.687988 45.567871 L 397.687988 49.000000 L 396.694336 49.000000 L 396.694336 48.108398 Q 396.377441 48.645508 395.896729 48.900635 Q 395.416016 49.155762 394.728516 49.155762 Q 393.810059 49.155762 393.262207 48.637451 Q 392.714355 48.119141 392.714355 47.249023 Q 392.714355 46.244629 393.388428 45.723633 Q 394.062500 45.202637 395.367676 45.202637 L 396.694336 45.202637 L 396.694336 45.046875 Q 396.688965 44.327148 396.329102 44.002197 Q 395.969238 43.677246 395.179688 43.677246 Q 394.674805 43.677246 394.159180 43.822266 Q 393.643555 43.967285 393.154785 44.246582 L 393.154785 43.258301 Q 393.702637 43.048828 394.204834 42.944092 Q 394.707031 42.839355 395.179688 42.839355 Q 395.926270 42.839355 396.455322 43.059570 Q 396.984375 43.279785 397.312012 43.720215 Q 397.516113 43.988770 397.602051 44.383545 Q 397.687988 44.778320 397.687988 45.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 400.869141 40.116211 L 401.728516 40.116211 Q 402.539551 41.383789 402.937012 42.597656 Q 403.334473 43.811523 403.334473 45.009277 Q 403.334473 46.217773 402.937012 47.431641 Q 402.539551 48.645508 401.728516 49.913086 L 400.869141 49.913086 Q 401.583496 48.666992 401.935303 47.447754 Q 402.287109 46.228516 402.287109 45.009277 Q 402.287109 43.784668 401.935303 42.565430 Q 401.583496 41.346191 400.869141 40.116211 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 464.878906 52.319336 L 464.878906 38.300781 L 465.738281 38.300781 L 465.738281 52.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.015625 32.498047 L 27.015625 32.981445 L 22.734863 32.981445 L 22.734863 33.013672 Q 22.691895 34.243652 23.204834 34.780762 Q 23.717773 35.317871 24.652344 35.317871 Q 25.125000 35.317871 25.640625 35.167480 Q 26.156250 35.017090 26.741699 34.710938 L 26.741699 35.693848 Q 26.177734 35.924805 25.654053 36.040283 Q 25.130371 36.155762 24.641602 36.155762 Q 23.239746 36.155762 22.450195 35.315186 Q 21.660645 34.474609 21.660645 32.997559 Q 21.660645 31.558105 22.434082 30.698730 Q 23.207520 29.839355 24.496582 29.839355 Q 25.645996 29.839355 26.309326 30.618164 Q 26.972656 31.396973 27.015625 32.498047 Z M 26.027344 32.208008 Q 25.962891 31.584961 25.573486 31.131104 Q 25.184082 30.677246 24.453613 30.677246 Q 23.739258 30.677246 23.277344 31.149902 Q 22.815430 31.622559 22.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 33.226074 30.193848 L 33.226074 31.160645 Q 32.801758 30.913574 32.372070 30.790039 Q 31.942383 30.666504 31.496582 30.666504 Q 30.825195 30.666504 30.494873 30.884033 Q 30.164551 31.101562 30.164551 31.547363 Q 30.164551 31.950195 30.411621 32.148926 Q 30.658691 32.347656 31.641602 32.535645 L 32.039062 32.610840 Q 32.774902 32.750488 33.153564 33.169434 Q 33.532227 33.588379 33.532227 34.259766 Q 33.532227 35.151367 32.898438 35.653564 Q 32.264648 36.155762 31.136719 36.155762 Q 30.690918 36.155762 30.202148 36.061768 Q 29.713379 35.967773 29.144043 35.779785 L 29.144043 34.759277 Q 29.697266 35.043945 30.202148 35.186279 Q 30.707031 35.328613 31.158203 35.328613 Q 31.813477 35.328613 32.173340 35.062744 Q 32.533203 34.796875 32.533203 34.318848 Q 32.533203 33.631348 31.217285 33.368164 L 31.174316 33.357422 L 30.803711 33.282227 Q 29.949707 33.115723 29.557617 32.720947 Q 29.165527 32.326172 29.165527 31.644043 Q 29.165527 30.779297 29.750977 30.309326 Q 30.336426 29.839355 31.421387 29.839355 Q 31.904785 29.839355 32.350586 29.927979 Q 32.796387 30.016602 33.226074 30.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 38.297852 28.276367 L 38.297852 29.984375 L 40.542969 29.984375 L 40.542969 30.752441 L 38.297852 30.752441 L 38.297852 34.018066 Q 38.297852 34.684082 38.550293 34.947266 Q 38.802734 35.210449 39.431152 35.210449 L 40.542969 35.210449 L 40.542969 36.000000 L 39.334473 36.000000 Q 38.222656 36.000000 37.766113 35.554199 Q 37.309570 35.108398 37.309570 34.018066 L 37.309570 30.752441 L 35.703613 30.752441 L 35.703613 29.984375 L 37.309570 29.984375 L 37.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 44.089355 33.830078 L 45.979980 33.830078 L 45.979980 36.000000 L 44.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 50.697266 30.005859 L 53.227051 30.005859 L 53.227051 35.231934 L 55.187500 35.231934 L 55.187500 36.000000 L 50.278320 36.000000 L 50.278320 35.231934 L 52.238770 35.231934 L 52.238770 30.773926 L 50.697266 30.773926 Z M 52.238770 27.669434 L 53.227051 27.669434 L 53.227051 28.915527 L 52.238770 28.915527 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 59.861816 33.824707 Q 59.861816 34.490723 60.106201 34.829102 Q 60.350586 35.167480 60.828613 35.167480 L 61.983398 35.167480 L 61.983398 36.000000 L 60.731934 36.000000 Q 59.845703 36.000000 59.359619 35.433350 Q 58.873535 34.866699 58.873535 33.824707 L 58.873535 28.383789 L 57.289062 28.383789 L 57.289062 27.610352 L 59.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 65.014160 35.242676 L 65.014160 38.288086 L 64.020508 38.288086 L 64.020508 29.984375 L 65.014160 29.984375 L 65.014160 30.752441 Q 65.261230 30.306641 65.672119 30.072998 Q 66.083008 29.839355 66.620117 29.839355 Q 67.710449 29.839355 68.330811 30.682617 Q 68.951172 31.525879 68.951172 33.019043 Q 68.951172 34.485352 68.328125 35.320557 Q 67.705078 36.155762 66.620117 36.155762 Q 66.072266 36.155762 65.661377 35.922119 Q 65.250488 35.688477 65.014160 35.242676 Z M 67.914551 32.997559 Q 67.914551 31.848145 67.552002 31.262695 Q 67.189453 30.677246 66.475098 30.677246 Q 65.755371 30.677246 65.384766 31.265381 Q 65.014160 31.853516 65.014160 32.997559 Q 65.014160 34.136230 65.384766 34.727051 Q 65.755371 35.317871 66.475098 35.317871 Q 67.189453 35.317871 67.552002 34.732422 Q 67.914551 34.146973 67.914551 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 70.537109 29.984375 L 71.562988 29.984375 L 73.308594 35.033203 L 75.059570 29.984375 L 76.085449 29.984375 L 73.947754 36.000000 L 72.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 80.947754 28.969238 L 78.417969 33.207031 L 80.947754 33.207031 Z M 80.770508 27.980957 L 82.027344 27.980957 L 82.027344 33.207031 L 83.096191 33.207031 L 83.096191 34.087891 L 82.027344 34.087891 L 82.027344 36.000000 L 80.947754 36.000000 L 80.947754 34.087891 L 77.547852 34.087891 L 77.547852 33.062012 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 84.547852 32.111328 L 90.241211 32.111328 L 90.241211 33.019043 L 84.547852 33.019043 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 95.608398 30.752441 L 95.608398 27.642578 L 96.596680 27.642578 L 96.596680 36.000000 L 95.608398 36.000000 L 95.608398 35.242676 Q 95.361328 35.688477 94.950439 35.922119 Q 94.539551 36.155762 94.002441 36.155762 Q 92.912109 36.155762 92.286377 35.309814 Q 91.660645 34.463867 91.660645 32.976074 Q 91.660645 31.509766 92.289062 30.674561 Q 92.917480 29.839355 94.002441 29.839355 Q 94.544922 29.839355 94.958496 30.072998 Q 95.372070 30.306641 95.608398 30.752441 Z M 92.702637 32.997559 Q 92.702637 34.146973 93.067871 34.732422 Q 93.433105 35.317871 94.147461 35.317871 Q 94.861816 35.317871 95.235107 34.727051 Q 95.608398 34.136230 95.608398 32.997559 Q 95.608398 31.853516 95.235107 31.265381 Q 94.861816 30.677246 94.147461 30.677246 Q 93.433105 30.677246 93.067871 31.262695 Q 92.702637 31.848145 92.702637 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.015625 32.498047 L 104.015625 32.981445 L 99.734863 32.981445 L 99.734863 33.013672 Q 99.691895 34.243652 100.204834 34.780762 Q 100.717773 35.317871 101.652344 35.317871 Q 102.125000 35.317871 102.640625 35.167480 Q 103.156250 35.017090 103.741699 34.710938 L 103.741699 35.693848 Q 103.177734 35.924805 102.654053 36.040283 Q 102.130371 36.155762 101.641602 36.155762 Q 100.239746 36.155762 99.450195 35.315186 Q 98.660645 34.474609 98.660645 32.997559 Q 98.660645 31.558105 99.434082 30.698730 Q 100.207520 29.839355 101.496582 29.839355 Q 102.645996 29.839355 103.309326 30.618164 Q 103.972656 31.396973 104.015625 32.498047 Z M 103.027344 32.208008 Q 102.962891 31.584961 102.573486 31.131104 Q 102.184082 30.677246 101.453613 30.677246 Q 100.739258 30.677246 100.277344 31.149902 Q 99.815430 31.622559 99.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 105.537109 29.984375 L 106.562988 29.984375 L 108.308594 35.033203 L 110.059570 29.984375 L 111.085449 29.984375 L 108.947754 36.000000 L 107.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 114.089355 33.830078 L 115.979980 33.830078 L 115.979980 36.000000 L 114.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.015625 32.498047 L 125.015625 32.981445 L 120.734863 32.981445 L 120.734863 33.013672 Q 120.691895 34.243652 121.204834 34.780762 Q 121.717773 35.317871 122.652344 35.317871 Q 123.125000 35.317871 123.640625 35.167480 Q 124.156250 35.017090 124.741699 34.710938 L 124.741699 35.693848 Q 124.177734 35.924805 123.654053 36.040283 Q 123.130371 36.155762 122.641602 36.155762 Q 121.239746 36.155762 120.450195 35.315186 Q 119.660645 34.474609 119.660645 32.997559 Q 119.660645 31.558105 120.434082 30.698730 Q 121.207520 29.839355 122.496582 29.839355 Q 123.645996 29.839355 124.309326 30.618164 Q 124.972656 31.396973 125.015625 32.498047 Z M 124.027344 32.208008 Q 123.962891 31.584961 123.573486 31.131104 Q 123.184082 30.677246 122.453613 30.677246 Q 121.739258 30.677246 121.277344 31.149902 Q 120.815430 31.622559 120.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 129.630859 30.596680 Q 129.813477 30.209961 130.095459 30.024658 Q 130.377441 29.839355 130.774902 29.839355 Q 131.500000 29.839355 131.798096 30.400635 Q 132.096191 30.961914 132.096191 32.514160 L 132.096191 36.000000 L 131.193848 36.000000 L 131.193848 32.557129 Q 131.193848 31.284180 131.051514 30.975342 Q 130.909180 30.666504 130.533203 30.666504 Q 130.103516 30.666504 129.945068 30.996826 Q 129.786621 31.327148 129.786621 32.557129 L 129.786621 36.000000 L 128.884277 36.000000 L 128.884277 32.557129 Q 128.884277 31.268066 128.731201 30.967285 Q 128.578125 30.666504 128.180664 30.666504 Q 127.788574 30.666504 127.635498 30.996826 Q 127.482422 31.327148 127.482422 32.557129 L 127.482422 36.000000 L 126.585449 36.000000 L 126.585449 29.984375 L 127.482422 29.984375 L 127.482422 30.500000 Q 127.659668 30.177734 127.925537 30.008545 Q 128.191406 29.839355 128.529785 29.839355 Q 128.937988 29.839355 129.209229 30.027344 Q 129.480469 30.215332 129.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 136.630859 30.596680 Q 136.813477 30.209961 137.095459 30.024658 Q 137.377441 29.839355 137.774902 29.839355 Q 138.500000 29.839355 138.798096 30.400635 Q 139.096191 30.961914 139.096191 32.514160 L 139.096191 36.000000 L 138.193848 36.000000 L 138.193848 32.557129 Q 138.193848 31.284180 138.051514 30.975342 Q 137.909180 30.666504 137.533203 30.666504 Q 137.103516 30.666504 136.945068 30.996826 Q 136.786621 31.327148 136.786621 32.557129 L 136.786621 36.000000 L 135.884277 36.000000 L 135.884277 32.557129 Q 135.884277 31.268066 135.731201 30.967285 Q 135.578125 30.666504 135.180664 30.666504 Q 134.788574 30.666504 134.635498 30.996826 Q 134.482422 31.327148 134.482422 32.557129 L 134.482422 36.000000 L 133.585449 36.000000 L 133.585449 29.984375 L 134.482422 29.984375 L 134.482422 30.500000 Q 134.659668 30.177734 134.925537 30.008545 Q 135.191406 29.839355 135.529785 29.839355 Q 135.937988 29.839355 136.209229 30.027344 Q 136.480469 30.215332 136.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.015625 32.498047 L 146.015625 32.981445 L 141.734863 32.981445 L 141.734863 33.013672 Q 141.691895 34.243652 142.204834 34.780762 Q 142.717773 35.317871 143.652344 35.317871 Q 144.125000 35.317871 144.640625 35.167480 Q 145.156250 35.017090 145.741699 34.710938 L 145.741699 35.693848 Q 145.177734 35.924805 144.654053 36.040283 Q 144.130371 36.155762 143.641602 36.155762 Q 142.239746 36.155762 141.450195 35.315186 Q 140.660645 34.474609 140.660645 32.997559 Q 140.660645 31.558105 141.434082 30.698730 Q 142.207520 29.839355 143.496582 29.839355 Q 144.645996 29.839355 145.309326 30.618164 Q 145.972656 31.396973 146.015625 32.498047 Z M 145.027344 32.208008 Q 144.962891 31.584961 144.573486 31.131104 Q 144.184082 30.677246 143.453613 30.677246 Q 142.739258 30.677246 142.277344 31.149902 Q 141.815430 31.622559 141.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 150.297852 28.276367 L 150.297852 29.984375 L 152.542969 29.984375 L 152.542969 30.752441 L 150.297852 30.752441 L 150.297852 34.018066 Q 150.297852 34.684082 150.550293 34.947266 Q 150.802734 35.210449 151.431152 35.210449 L 152.542969 35.210449 L 152.542969 36.000000 L 151.334473 36.000000 Q 150.222656 36.000000 149.766113 35.554199 Q 149.309570 35.108398 149.309570 34.018066 L 149.309570 30.752441 L 147.703613 30.752441 L 147.703613 29.984375 L 149.309570 29.984375 L 149.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 163.878906 39.319336 L 163.878906 25.300781 L 164.738281 25.300781 L 164.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 303.878906 39.319336 L 303.878906 25.300781 L 304.738281 25.300781 L 304.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 318.297852 28.276367 L 318.297852 29.984375 L 320.542969 29.984375 L 320.542969 30.752441 L 318.297852 30.752441 L 318.297852 34.018066 Q 318.297852 34.684082 318.550293 34.947266 Q 318.802734 35.210449 319.431152 35.210449 L 320.542969 35.210449 L 320.542969 36.000000 L 319.334473 36.000000 Q 318.222656 36.000000 317.766113 35.554199 Q 317.309570 35.108398 317.309570 34.018066 L 317.309570 30.752441 L 315.703613 30.752441 L 315.703613 29.984375 L 317.309570 29.984375 L 317.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.015625 32.498047 L 328.015625 32.981445 L 323.734863 32.981445 L 323.734863 33.013672 Q 323.691895 34.243652 324.204834 34.780762 Q 324.717773 35.317871 325.652344 35.317871 Q 326.125000 35.317871 326.640625 35.167480 Q 327.156250 35.017090 327.741699 34.710938 L 327.741699 35.693848 Q 327.177734 35.924805 326.654053 36.040283 Q 326.130371 36.155762 325.641602 36.155762 Q 324.239746 36.155762 323.450195 35.315186 Q 322.660645 34.474609 322.660645 32.997559 Q 322.660645 31.558105 323.434082 30.698730 Q 324.207520 29.839355 325.496582 29.839355 Q 326.645996 29.839355 327.309326 30.618164 Q 327.972656 31.396973 328.015625 32.498047 Z M 327.027344 32.208008 Q 326.962891 31.584961 326.573486 31.131104 Q 326.184082 30.677246 325.453613 30.677246 Q 324.739258 30.677246 324.277344 31.149902 Q 323.815430 31.622559 323.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 334.226074 30.193848 L 334.226074 31.160645 Q 333.801758 30.913574 333.372070 30.790039 Q 332.942383 30.666504 332.496582 30.666504 Q 331.825195 30.666504 331.494873 30.884033 Q 331.164551 31.101562 331.164551 31.547363 Q 331.164551 31.950195 331.411621 32.148926 Q 331.658691 32.347656 332.641602 32.535645 L 333.039062 32.610840 Q 333.774902 32.750488 334.153564 33.169434 Q 334.532227 33.588379 334.532227 34.259766 Q 334.532227 35.151367 333.898438 35.653564 Q 333.264648 36.155762 332.136719 36.155762 Q 331.690918 36.155762 331.202148 36.061768 Q 330.713379 35.967773 330.144043 35.779785 L 330.144043 34.759277 Q 330.697266 35.043945 331.202148 35.186279 Q 331.707031 35.328613 332.158203 35.328613 Q 332.813477 35.328613 333.173340 35.062744 Q 333.533203 34.796875 333.533203 34.318848 Q 333.533203 33.631348 332.217285 33.368164 L 332.174316 33.357422 L 331.803711 33.282227 Q 330.949707 33.115723 330.557617 32.720947 Q 330.165527 32.326172 330.165527 31.644043 Q 330.165527 30.779297 330.750977 30.309326 Q 331.336426 29.839355 332.421387 29.839355 Q 332.904785 29.839355 333.350586 29.927979 Q 333.796387 30.016602 334.226074 30.193848 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 339.297852 28.276367 L 339.297852 29.984375 L 341.542969 29.984375 L 341.542969 30.752441 L 339.297852 30.752441 L 339.297852 34.018066 Q 339.297852 34.684082 339.550293 34.947266 Q 339.802734 35.210449 340.431152 35.210449 L 341.542969 35.210449 L 341.542969 36.000000 L 340.334473 36.000000 Q 339.222656 36.000000 338.766113 35.554199 Q 338.309570 35.108398 338.309570 34.018066 L 338.309570 30.752441 L 336.703613 30.752441 L 336.703613 29.984375 L 338.309570 29.984375 L 338.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 345.089355 33.830078 L 346.979980 33.830078 L 346.979980 36.000000 L 345.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 351.697266 30.005859 L 354.227051 30.005859 L 354.227051 35.231934 L 356.187500 35.231934 L 356.187500 36.000000 L 351.278320 36.000000 L 351.278320 35.231934 L 353.238770 35.231934 L 353.238770 30.773926 L 351.697266 30.773926 Z M 353.238770 27.669434 L 354.227051 27.669434 L 354.227051 28.915527 L 353.238770 28.915527 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 360.861816 33.824707 Q 360.861816 34.490723 361.106201 34.829102 Q 361.350586 35.167480 361.828613 35.167480 L 362.983398 35.167480 L 362.983398 36.000000 L 361.731934 36.000000 Q 360.845703 36.000000 360.359619 35.433350 Q 359.873535 34.866699 359.873535 33.824707 L 359.873535 28.383789 L 358.289062 28.383789 L 358.289062 27.610352 L 360.861816 27.610352 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 366.014160 35.242676 L 366.014160 38.288086 L 365.020508 38.288086 L 365.020508 29.984375 L 366.014160 29.984375 L 366.014160 30.752441 Q 366.261230 30.306641 366.672119 30.072998 Q 367.083008 29.839355 367.620117 29.839355 Q 368.710449 29.839355 369.330811 30.682617 Q 369.951172 31.525879 369.951172 33.019043 Q 369.951172 34.485352 369.328125 35.320557 Q 368.705078 36.155762 367.620117 36.155762 Q 367.072266 36.155762 366.661377 35.922119 Q 366.250488 35.688477 366.014160 35.242676 Z M 368.914551 32.997559 Q 368.914551 31.848145 368.552002 31.262695 Q 368.189453 30.677246 367.475098 30.677246 Q 366.755371 30.677246 366.384766 31.265381 Q 366.014160 31.853516 366.014160 32.997559 Q 366.014160 34.136230 366.384766 34.727051 Q 366.755371 35.317871 367.475098 35.317871 Q 368.189453 35.317871 368.552002 34.732422 Q 368.914551 34.146973 368.914551 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 371.537109 29.984375 L 372.562988 29.984375 L 374.308594 35.033203 L 376.059570 29.984375 L 377.085449 29.984375 L 374.947754 36.000000 L 373.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 381.947754 28.969238 L 379.417969 33.207031 L 381.947754 33.207031 Z M 381.770508 27.980957 L 383.027344 27.980957 L 383.027344 33.207031 L 384.096191 33.207031 L 384.096191 34.087891 L 383.027344 34.087891 L 383.027344 36.000000 L 381.947754 36.000000 L 381.947754 34.087891 L 378.547852 34.087891 L 378.547852 33.062012 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 385.547852 32.111328 L 391.241211 32.111328 L 391.241211 33.019043 L 385.547852 33.019043 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 396.608398 30.752441 L 396.608398 27.642578 L 397.596680 27.642578 L 397.596680 36.000000 L 396.608398 36.000000 L 396.608398 35.242676 Q 396.361328 35.688477 395.950439 35.922119 Q 395.539551 36.155762 395.002441 36.155762 Q 393.912109 36.155762 393.286377 35.309814 Q 392.660645 34.463867 392.660645 32.976074 Q 392.660645 31.509766 393.289062 30.674561 Q 393.917480 29.839355 395.002441 29.839355 Q 395.544922 29.839355 395.958496 30.072998 Q 396.372070 30.306641 396.608398 30.752441 Z M 393.702637 32.997559 Q 393.702637 34.146973 394.067871 34.732422 Q 394.433105 35.317871 395.147461 35.317871 Q 395.861816 35.317871 396.235107 34.727051 Q 396.608398 34.136230 396.608398 32.997559 Q 396.608398 31.853516 396.235107 31.265381 Q 395.861816 30.677246 395.147461 30.677246 Q 394.433105 30.677246 394.067871 31.262695 Q 393.702637 31.848145 393.702637 32.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.015625 32.498047 L 405.015625 32.981445 L 400.734863 32.981445 L 400.734863 33.013672 Q 400.691895 34.243652 401.204834 34.780762 Q 401.717773 35.317871 402.652344 35.317871 Q 403.125000 35.317871 403.640625 35.167480 Q 404.156250 35.017090 404.741699 34.710938 L 404.741699 35.693848 Q 404.177734 35.924805 403.654053 36.040283 Q 403.130371 36.155762 402.641602 36.155762 Q 401.239746 36.155762 400.450195 35.315186 Q 399.660645 34.474609 399.660645 32.997559 Q 399.660645 31.558105 400.434082 30.698730 Q 401.207520 29.839355 402.496582 29.839355 Q 403.645996 29.839355 404.309326 30.618164 Q 404.972656 31.396973 405.015625 32.498047 Z M 404.027344 32.208008 Q 403.962891 31.584961 403.573486 31.131104 Q 403.184082 30.677246 402.453613 30.677246 Q 401.739258 30.677246 401.277344 31.149902 Q 400.815430 31.622559 400.772461 32.213379 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 406.537109 29.984375 L 407.562988 29.984375 L 409.308594 35.033203 L 411.059570 29.984375 L 412.085449 29.984375 L 409.947754 36.000000 L 408.674805 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 415.089355 33.830078 L 416.979980 33.830078 L 416.979980 36.000000 L 415.089355 36.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 423.630859 30.596680 Q 423.813477 30.209961 424.095459 30.024658 Q 424.377441 29.839355 424.774902 29.839355 Q 425.500000 29.839355 425.798096 30.400635 Q 426.096191 30.961914 426.096191 32.514160 L 426.096191 36.000000 L 425.193848 36.000000 L 425.193848 32.557129 Q 425.193848 31.284180 425.051514 30.975342 Q 424.909180 30.666504 424.533203 30.666504 Q 424.103516 30.666504 423.945068 30.996826 Q 423.786621 31.327148 423.786621 32.557129 L 423.786621 36.000000 L 422.884277 36.000000 L 422.884277 32.557129 Q 422.884277 31.268066 422.731201 30.967285 Q 422.578125 30.666504 422.180664 30.666504 Q 421.788574 30.666504 421.635498 30.996826 Q 421.482422 31.327148 421.482422 32.557129 L 421.482422 36.000000 L 420.585449 36.000000 L 420.585449 29.984375 L 421.482422 29.984375 L 421.482422 30.500000 Q 421.659668 30.177734 421.925537 30.008545 Q 422.191406 29.839355 422.529785 29.839355 Q 422.937988 29.839355 423.209229 30.027344 Q 423.480469 30.215332 423.630859 30.596680 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 430.770508 32.976074 L 430.442871 32.976074 Q 429.578125 32.976074 429.140381 33.279541 Q 428.702637 33.583008 428.702637 34.184570 Q 428.702637 34.727051 429.030273 35.027832 Q 429.357910 35.328613 429.937988 35.328613 Q 430.754395 35.328613 431.221680 34.761963 Q 431.688965 34.195312 431.694336 33.196289 L 431.694336 32.976074 Z M 432.687988 32.567871 L 432.687988 36.000000 L 431.694336 36.000000 L 431.694336 35.108398 Q 431.377441 35.645508 430.896729 35.900635 Q 430.416016 36.155762 429.728516 36.155762 Q 428.810059 36.155762 428.262207 35.637451 Q 427.714355 35.119141 427.714355 34.249023 Q 427.714355 33.244629 428.388428 32.723633 Q 429.062500 32.202637 430.367676 32.202637 L 431.694336 32.202637 L 431.694336 32.046875 Q 431.688965 31.327148 431.329102 31.002197 Q 430.969238 30.677246 430.179688 30.677246 Q 429.674805 30.677246 429.159180 30.822266 Q 428.643555 30.967285 428.154785 31.246582 L 428.154785 30.258301 Q 428.702637 30.048828 429.204834 29.944092 Q 429.707031 29.839355 430.179688 29.839355 Q 430.926270 29.839355 431.455322 30.059570 Q 431.984375 30.279785 432.312012 30.720215 Q 432.516113 30.988770 432.602051 31.383545 Q 432.687988 31.778320 432.687988 32.567871 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.203613 31.225098 Q 439.886719 30.978027 439.559082 30.865234 Q 439.231445 30.752441 438.839355 30.752441 Q 437.915527 30.752441 437.426758 31.332520 Q 436.937988 31.912598 436.937988 33.008301 L 436.937988 36.000000 L 435.944336 36.000000 L 435.944336 29.984375 L 436.937988 29.984375 L 436.937988 31.160645 Q 437.185059 30.521484 437.697998 30.180420 Q 438.210938 29.839355 438.914551 29.839355 Q 439.279785 29.839355 439.596680 29.930664 Q 439.913574 30.021973 440.203613 30.215332 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 444.297852 28.276367 L 444.297852 29.984375 L 446.542969 29.984375 L 446.542969 30.752441 L 444.297852 30.752441 L 444.297852 34.018066 Q 444.297852 34.684082 444.550293 34.947266 Q 444.802734 35.210449 445.431152 35.210449 L 446.542969 35.210449 L 446.542969 36.000000 L 445.334473 36.000000 Q 444.222656 36.000000 443.766113 35.554199 Q 443.309570 35.108398 443.309570 34.018066 L 443.309570 30.752441 L 441.703613 30.752441 L 441.703613 29.984375 L 443.309570 29.984375 L 443.309570 28.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 452.608398 34.066406 Q 452.361328 34.694824 451.979980 35.720703 Q 451.448242 37.138672 451.265625 37.450195 Q 451.018555 37.869141 450.647949 38.078613 Q 450.277344 38.288086 449.783203 38.288086 L 448.988281 38.288086 L 448.988281 37.460938 L 449.573730 37.460938 Q 450.008789 37.460938 450.255859 37.208496 Q 450.502930 36.956055 450.884277 35.903320 L 448.558594 29.984375 L 449.605957 29.984375 L 451.389160 34.689453 L 453.145508 29.984375 L 454.192871 29.984375 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 464.878906 39.319336 L 464.878906 25.300781 L 465.738281 25.300781 L 465.738281 39.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 162.256836 20.142578 L 166.978027 22.505859 L 166.978027 17.779297 Z M 161.032227 20.142578 L 167.590332 16.866211 L 167.590332 23.418945 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 167.892578 20.335938 L 167.892578 19.412109 L 174.729980 19.412109 L 174.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 174.892578 20.335938 L 174.892578 19.412109 L 181.729980 19.412109 L 181.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 181.892578 20.335938 L 181.892578 19.412109 L 188.729980 19.412109 L 188.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 188.892578 20.335938 L 188.892578 19.412109 L 195.729980 19.412109 L 195.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 195.892578 20.335938 L 195.892578 19.412109 L 202.729980 19.412109 L 202.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 203.735840 14.980957 L 204.826172 14.980957 L 204.826172 18.053223 L 207.796387 18.053223 L 207.796387 14.980957 L 208.886719 14.980957 L 208.886719 23.000000 L 207.796387 23.000000 L 207.796387 18.966309 L 204.826172 18.966309 L 204.826172 23.000000 L 203.735840 23.000000 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 213.297852 15.276367 L 213.297852 16.984375 L 215.542969 16.984375 L 215.542969 17.752441 L 213.297852 17.752441 L 213.297852 21.018066 Q 213.297852 21.684082 213.550293 21.947266 Q 213.802734 22.210449 214.431152 22.210449 L 215.542969 22.210449 L 215.542969 23.000000 L 214.334473 23.000000 Q 213.222656 23.000000 212.766113 22.554199 Q 212.309570 22.108398 212.309570 21.018066 L 212.309570 17.752441 L 210.703613 17.752441 L 210.703613 16.984375 L 212.309570 16.984375 L 212.309570 15.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 220.297852 15.276367 L 220.297852 16.984375 L 222.542969 16.984375 L 222.542969 17.752441 L 220.297852 17.752441 L 220.297852 21.018066 Q 220.297852 21.684082 220.550293 21.947266 Q 220.802734 22.210449 221.431152 22.210449 L 222.542969 22.210449 L 222.542969 23.000000 L 221.334473 23.000000 Q 220.222656 23.000000 219.766113 22.554199 Q 219.309570 22.108398 219.309570 21.018066 L 219.309570 17.752441 L 217.703613 17.752441 L 217.703613 16.984375 L 219.309570 16.984375 L 219.309570 15.276367 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 226.014160 22.242676 L 226.014160 25.288086 L 225.020508 25.288086 L 225.020508 16.984375 L 226.014160 16.984375 L 226.014160 17.752441 Q 226.261230 17.306641 226.672119 17.072998 Q 227.083008 16.839355 227.620117 16.839355 Q 228.710449 16.839355 229.330811 17.682617 Q 229.951172 18.525879 229.951172 20.019043 Q 229.951172 21.485352 229.328125 22.320557 Q 228.705078 23.155762 227.620117 23.155762 Q 227.072266 23.155762 226.661377 22.922119 Q 226.250488 22.688477 226.014160 22.242676 Z M 228.914551 19.997559 Q 228.914551 18.848145 228.552002 18.262695 Q 228.189453 17.677246 227.475098 17.677246 Q 226.755371 17.677246 226.384766 18.265381 Q 226.014160 18.853516 226.014160 19.997559 Q 226.014160 21.136230 226.384766 21.727051 Q 226.755371 22.317871 227.475098 22.317871 Q 228.189453 22.317871 228.552002 21.732422 Q 228.914551 21.146973 228.914551 19.997559 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 235.774902 14.980957 L 236.795410 14.980957 L 232.573730 24.020508 L 231.547852 24.020508 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 240.003418 22.086914 L 243.687988 22.086914 L 243.687988 23.000000 L 238.816406 23.000000 L 238.816406 22.086914 Q 239.820801 21.028809 240.572754 20.217773 Q 241.324707 19.406738 241.609375 19.073730 Q 242.146484 18.418457 242.334473 18.012939 Q 242.522461 17.607422 242.522461 17.183105 Q 242.522461 16.511719 242.127686 16.130371 Q 241.732910 15.749023 241.045410 15.749023 Q 240.556641 15.749023 240.019531 15.926270 Q 239.482422 16.103516 238.880859 16.463379 L 238.880859 15.367676 Q 239.434082 15.104492 239.968506 14.970215 Q 240.502930 14.835938 241.023926 14.835938 Q 242.200195 14.835938 242.917236 15.461670 Q 243.634277 16.087402 243.634277 17.102539 Q 243.634277 17.618164 243.395264 18.133789 Q 243.156250 18.649414 242.619141 19.272461 Q 242.318359 19.621582 241.746338 20.239258 Q 241.174316 20.856934 240.003418 22.086914 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 244.892578 20.335938 L 244.892578 19.412109 L 251.729980 19.412109 L 251.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 251.892578 20.335938 L 251.892578 19.412109 L 258.729980 19.412109 L 258.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 258.892578 20.335938 L 258.892578 19.412109 L 265.729980 19.412109 L 265.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 265.892578 20.335938 L 265.892578 19.412109 L 272.729980 19.412109 L 272.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 272.892578 20.335938 L 272.892578 19.412109 L 279.729980 19.412109 L 279.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 279.892578 20.335938 L 279.892578 19.412109 L 286.729980 19.412109 L 286.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 286.892578 20.335938 L 286.892578 19.412109 L 293.729980 19.412109 L 293.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 293.892578 20.335938 L 293.892578 19.412109 L 300.729980 19.412109 L 300.729980 20.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 303.878906 26.319336 L 303.878906 20.335938 L 300.892578 20.335938 L 300.892578 19.412109 L 303.878906 19.412109 L 303.878906 12.300781 L 304.738281 12.300781 L 304.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 464.878906 26.319336 L 464.878906 12.300781 L 465.738281 12.300781 L 465.738281 26.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 6.892578 7.335938 L 6.892578 6.412109 L 13.729980 6.412109 L 13.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 13.892578 7.335938 L 13.892578 6.412109 L 20.729980 6.412109 L 20.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 20.892578 7.335938 L 20.892578 6.412109 L 27.729980 6.412109 L 27.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 27.892578 7.335938 L 27.892578 6.412109 L 34.729980 6.412109 L 34.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 34.892578 7.335938 L 34.892578 6.412109 L 41.729980 6.412109 L 41.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 41.892578 7.335938 L 41.892578 6.412109 L 48.729980 6.412109 L 48.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 48.892578 7.335938 L 48.892578 6.412109 L 55.729980 6.412109 L 55.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 55.892578 7.335938 L 55.892578 6.412109 L 62.729980 6.412109 L 62.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 62.892578 7.335938 L 62.892578 6.412109 L 69.729980 6.412109 L 69.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 69.892578 7.335938 L 69.892578 6.412109 L 76.729980 6.412109 L 76.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 76.892578 7.335938 L 76.892578 6.412109 L 83.729980 6.412109 L 83.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 83.892578 7.335938 L 83.892578 6.412109 L 90.729980 6.412109 L 90.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 90.892578 7.335938 L 90.892578 6.412109 L 97.729980 6.412109 L 97.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 97.892578 7.335938 L 97.892578 6.412109 L 104.729980 6.412109 L 104.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 104.892578 7.335938 L 104.892578 6.412109 L 111.729980 6.412109 L 111.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 111.892578 7.335938 L 111.892578 6.412109 L 118.729980 6.412109 L 118.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 118.892578 7.335938 L 118.892578 6.412109 L 125.729980 6.412109 L 125.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 125.892578 7.335938 L 125.892578 6.412109 L 132.729980 6.412109 L 132.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 132.892578 7.335938 L 132.892578 6.412109 L 139.729980 6.412109 L 139.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 139.892578 7.335938 L 139.892578 6.412109 L 146.729980 6.412109 L 146.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 146.892578 7.335938 L 146.892578 6.412109 L 153.729980 6.412109 L 153.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 153.892578 7.335938 L 153.892578 6.412109 L 160.729980 6.412109 L 160.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 163.878906 13.319336 L 163.878906 7.335938 L 160.892578 7.335938 L 160.892578 6.412109 L 164.738281 6.412109 L 164.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 303.878906 13.319336 L 303.878906 6.412109 L 307.729980 6.412109 L 307.729980 7.335938 L 304.738281 7.335938 L 304.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 307.892578 7.335938 L 307.892578 6.412109 L 314.729980 6.412109 L 314.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 314.892578 7.335938 L 314.892578 6.412109 L 321.729980 6.412109 L 321.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 321.892578 7.335938 L 321.892578 6.412109 L 328.729980 6.412109 L 328.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 328.892578 7.335938 L 328.892578 6.412109 L 335.729980 6.412109 L 335.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 335.892578 7.335938 L 335.892578 6.412109 L 342.729980 6.412109 L 342.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 342.892578 7.335938 L 342.892578 6.412109 L 349.729980 6.412109 L 349.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 349.892578 7.335938 L 349.892578 6.412109 L 356.729980 6.412109 L 356.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 356.892578 7.335938 L 356.892578 6.412109 L 363.729980 6.412109 L 363.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 363.892578 7.335938 L 363.892578 6.412109 L 370.729980 6.412109 L 370.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 370.892578 7.335938 L 370.892578 6.412109 L 377.729980 6.412109 L 377.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 377.892578 7.335938 L 377.892578 6.412109 L 384.729980 6.412109 L 384.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 384.892578 7.335938 L 384.892578 6.412109 L 391.729980 6.412109 L 391.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 391.892578 7.335938 L 391.892578 6.412109 L 398.729980 6.412109 L 398.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 398.892578 7.335938 L 398.892578 6.412109 L 405.729980 6.412109 L 405.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 405.892578 7.335938 L 405.892578 6.412109 L 412.729980 6.412109 L 412.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 412.892578 7.335938 L 412.892578 6.412109 L 419.729980 6.412109 L 419.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 419.892578 7.335938 L 419.892578 6.412109 L 426.729980 6.412109 L 426.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 426.892578 7.335938 L 426.892578 6.412109 L 433.729980 6.412109 L 433.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 433.892578 7.335938 L 433.892578 6.412109 L 440.729980 6.412109 L 440.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 440.892578 7.335938 L 440.892578 6.412109 L 447.729980 6.412109 L 447.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 447.892578 7.335938 L 447.892578 6.412109 L 454.729980 6.412109 L 454.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 454.892578 7.335938 L 454.892578 6.412109 L 461.729980 6.412109 L 461.729980 7.335938 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
      <path d="M 464.878906 13.319336 L 464.878906 7.335938 L 461.892578 7.335938 L 461.892578 6.412109 L 465.738281 6.412109 L 465.738281 13.319336 Z " fill="rgb(0, 0, 0)" fillOpacity="1.000000" />
    </svg>
    <h3 {...{
      "id": "marty-peer-account-configuration"
    }}>{`Marty Peer Account Configuration`}</h3>
    <p>{`This account is configured to have the following configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "__description": "Marty's peering account on Clara",
   "asset_code": "XRP",
   "asset_scale": 6,
   "btp_outgoing_token": null,
   "btp_uri": null,
   "http_endpoint": "https://marty-dot-ilpv4-dev.appspot.com/ilp",
   "http_outgoing_token": "{REDACTED}",
   "id": 0,
   "ilp_address": "test.ilpv4-dev.clara.marty",
   "max_packet_amount": 18446744073709551615,
   "min_balance": -1000000000000,
   "packets_per_minute_limit": null,
   "receive_routes": true,
   "round_trip_time": 500,
   "routing_relation": "Peer",
   "send_routes": true,
   "settle_threshold": null,
   "settle_to": null
}
`}</code></pre>
    <h2 {...{
      "id": "peer-with-clara"
    }}>{`Peer with Clara`}</h2>
    <p>{`Coming soon...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      